<template>
  <b-card>
    <b-row>
      <b-col class="col-9">
        <h4 v-if="titulo">
          {{ titulo }}
        </h4>
        <b-badge
          v-if="showAvisoConfirmacao"
          :variant="'light-danger'"
          style="margin-bottom: 15px; padding: 10px; width: 100%; white-space: normal;"
        >
          Após concluir as parcelas de entrada acesse o portal do E-cac e confirme as parcelas Remanescentes para dar continuidade ao seu parcelamento.
        </b-badge>
        <b-badge
          v-if="mensagemAlerta"
          variant="light-warning"
          style="margin-bottom: 15px; padding: 10px; white-space: normal;"
        >
          {{ mensagemAlerta }}
        </b-badge>
      </b-col>
      <b-col class="col-3">
        <download-em-lote
          :tipo-arquivo="`todos os parcelamentos ${parcelamentoBaixaLote(tipoParcelamento)}`"
          :tipo="`Parcelamento ${tipoParcelamento.toUpperCase()}`"
          style="white-space: nowrap;"
          @baixar-lote="baixarEmLote"
        />
      </b-col>
    </b-row>
    <parcelamento-list
      ref="dasParcelamentoList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :mostrar-acoes-padrao="false"
      :filtros-rapidos="filtrosRapidos"
    >
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <b-badge
          v-if="props.column.field === 'situacao'"
          :variant="getColorTag(props.row[props.column.field])"
          @click.stop="openAlertAvisoSituacao(props.row)"
        >
          <div :style="props.row[props.column.field].length > 26 ? 'width: 180px; white-space: normal;' : 'max-width: 180px;'">
            {{ props.row[props.column.field] }}
          </div>
        </b-badge>
        <span
          v-else-if="props.column.field === 'razaoSocial'"
          @click.stop="$refs[`acoes${props.row.id}`].abrirArquivo(props.row)"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
        <slot
          v-if="colunasCustom"
          name="colunasCustom"
          :props="props"
        />
        <div v-else>
          <b-badge
            v-if="props.column.field === 'parcelasEmAtraso'"
            :variant="props.row[props.column.field] == true ? 'light-danger' : 'light-success'"
          >
            {{ props.row[props.column.field] == true ? 'Sim' : 'Não' }}
          </b-badge>
          <div
            v-else-if="props.column.field === 'nroUltimaParcelaDe'"
            :id="`nmrParcela${props.row.id}`"
            class="alinhar-coluna"
            style="display: inline-flex;"
          >
            <span
              class="mr-50"
              style="white-space: nowrap;"
            >
              {{ props.row[props.column.field] }}
            </span>
            <icone-letra
              v-if="tipoParcelamento !== 'rfb' && props.row.totalParcelasEntrada != '' && props.row.totalParcelas == ''"
              letra="E"
              :class="'text-primary'"
            />
            <icone-letra
              v-else-if="tipoParcelamento !== 'rfb' && props.row.totalParcelas != '' && props.row.totalParcelasEntrada != ''"
              letra="R"
              :class="'text-warning'"
            />
            <b-tooltip
              v-if="props.row[props.column.field].includes('-')"
              :target="`nmrParcela${props.row.id}`"
              title="O parcelamento não se encontra liberado para impressão das guias. As parcelas serão disponibilizadas assim que forem liberadas no portal do ECAC."
            />
          </div>
        </div>

      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <slot
          v-if="acoesCustom"
          name="acoesCustom"
          :props="props"
        />
        <acoes
          v-else
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          :request-parcelas="getParcelas"
          :request-enviar-email="enviaParcelaEmail"
          :request-guia-parcelamento="getGuiaParcelamento"
          :tipo-parcelamento="tipoParcelamento"
          :api="apiParcelamento(tipoParcelamento)"
        />
      </template>
    </parcelamento-list></b-card>
</template>
<style>
.alinhar-coluna {
  text-align: center !important;
}
</style>
<script>
import colunas from './shared/components/colunas'
import api from './shared/services/api'
import useAviso from './shared/components/aviso/useAviso'

export default {
  components: {
    IconeLetra: () => import('@pilar/components/IconeLetra/IconeLetra.vue'),
    ParcelamentoList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    Acoes: () => import('./shared/components/acoes/Acoes.vue'),
    DownloadEmLote: () => import('@app/download-lote/DownloadEmLote.vue'),
  },
  props: {
    filtrosCustom: {
      type: Array,
      default: null,
    },
    filtrosRapidosCustom: {
      type: Array,
      default: null,
    },
    colunasCustom: {
      type: Boolean,
      default: false,
    },
    acoesCustom: {
      type: Boolean,
      default: false,
    },
    novasColunas: {
      type: Array,
      default: null,
    },
    titulo: {
      type: String,
      default: null,
    },
    placeHolder: {
      type: String,
      default: 'Buscar por: CNPJ/CPF e Razão social',
    },
    getColorCustom: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      botoesOption: null,
      linha: null,
      visualizacao: {
        acaoClass: 'col-td-acao-4',
        disabledCheckbox: true,
        placeholderBusca: null,
      },
      filtros: [
        {
          colunas: [
            'CadastroFederalSemMascara',
            'CadastroFederal',
            'RazaoSocial',
            'ConsultadoEm',
            'NumeroParcela',
            'MesParcelaAtual',
            'DataPedido',
          ],
          valor: null,
        },
      ],
      filtrosRapidos: [
        {
          id: 'EmParcelamento',
          colunas: ['Situacao'],
          label: 'Em parcelamento',
          valor: '1',
        },
        {
          id: 'StatusEmAtraso',
          colunas: ['StatusEmParcelamento'],
          label: 'Em atraso',
          valor: '2',
        },
        {
          id: 'StatusSemAtraso',
          colunas: ['StatusEmParcelamento'],
          label: 'Em dia',
          valor: '1',
        },
        {
          id: 'MesPaga',
          colunas: ['MesPaga'],
          label: 'Parcela do mês paga',
          valor: '1',
        },
        {
          id: 'MesNaoPaga',
          colunas: ['MesNaoPaga'],
          label: 'Parcela do mês não paga',
          valor: '2',
        },
        {
          id: 'SemEfeito',
          colunas: ['Situacao'],
          label: 'Sem efeito por solicitação do contribuinte',
          valor: '8',
        },
        {
          id: 'EncerradoPedido',
          colunas: ['Situacao'],
          label: 'Encerrado a pedido do contribuinte',
          valor: '2',
        },
        {
          id: 'EncerradoRescisao',
          colunas: ['Situacao'],
          label: 'Encerrado por rescisão',
          valor: '5',
        },
        {
          id: 'NaoValidado',
          colunas: ['Situacao'],
          label: 'Não validado - primeira parcela não paga',
          valor: '6',
        },
        {
          id: 'EncerradoLiquidacao',
          colunas: ['Situacao'],
          label: 'Encerrado por liquidação',
          valor: '4',
        },
        {
          id: 'AguardandoPrimeiroPagamento',
          colunas: ['Situacao'],
          label: 'Aguardando pagamento da 1ª parcela',
          valor: '7',
        }],
      criando: true,
      tipoParcelamento: '',
      filtroAtual: {},
      apiParcelamento: api,
    }
  },
  computed: {
    showAvisoConfirmacao() {
      let dataLimite = new Date()
      dataLimite = dataLimite.setMonth(1, 28)
      dataLimite += 60

      return this.tipoParcelamento.toUpperCase() === 'RELP' && new Date() < dataLimite
    },
    mensagemAlerta() {
      if (this.tipoParcelamento.toUpperCase() === 'DAS') {
        return 'O parcelamento do Simples Nacional será consultado a partir do dia 10 de cada mês.'
      }
      if (this.tipoParcelamento.toUpperCase() === 'PERT') {
        return 'O parcelamento do PERT-SN será consultado a partir do dia 10 de cada mês.'
      }
      if (this.tipoParcelamento.toUpperCase() === 'RELP') {
        return 'O parcelamento do RELP-SN será consultado a partir do dia 10 de cada mês.'
      }
      return null
    },

  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  created() {
    if (this.filtrosRapidosCustom && this.filtrosRapidosCustom.length > 0) {
      this.filtrosRapidos = this.filtrosRapidosCustom
    }
    if (this.filtrosCustom && this.filtrosCustom.length > 0) {
      this.filtros = this.filtrosCustom
    }
    
    this.tipoParcelamento = this.$route.meta.tipo
    this.visualizacao.colunas = this.tipoParcelamento === 'rfb' ? colunas[2].visible = true : this.novasColunas
    this.visualizacao.colunas = this.tipoParcelamento !== 'rfb' ? colunas[2].visible = false : this.novasColunas
    this.visualizacao.colunas = this.colunasCustom ? this.novasColunas : colunas
    this.visualizacao.evento = this.carregar
    this.visualizacao.placeholderBusca = this.placeHolder
  },
  methods: {
    parcelamentoBaixaLote(tipoParcelamento) {
      if (!(tipoParcelamento.includes('-') || tipoParcelamento.includes('_'))) {
        return tipoParcelamento.toUpperCase()
      }
      return tipoParcelamento.replace('-', ' ').replace('_', ' ').replace(/(?:^\w|[A-Z]|\b\w)/g, word => word.toUpperCase())
    },
    openAlertAvisoSituacao(row) {
      if (row.situacao === 'CPF sem dados' || row.situacao === 'Sem acesso para filial') {
        useAviso(row)
      }
    },
    getColorTag(value) {
      if (this.getColorCustom) {
        return this.getColorCustom(value)
      }

      if (value === 'Em parcelamento' || value === 'Deferida e consolidada') {
        return 'light-primary'
      }
      if (value === 'Em parcelamento - Aguardando confirmação de remanescentes') {
        return 'light-danger'
      }
      return 'light-warning'
    },
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro
      return api(this.tipoParcelamento).getPaginado(pagina, tamanhoPagina, filtro)
    },
    getParcelas(parcelamentoId) {
      return api(this.tipoParcelamento).getParcelas(parcelamentoId)
    },
    getParcelasRecentes(parcelamentoId) {
      return api(this.tipoParcelamento).getParcelasRecentes(parcelamentoId)
    },
    enviaParcelaEmail(nomeEmpresa, emailEmpresa, emailCc, id, parcelaId, mensagemAdicional, definirEmailPadrao) {
      return api(this.tipoParcelamento).enviarEmail(nomeEmpresa, emailEmpresa, emailCc, id, parcelaId, mensagemAdicional, definirEmailPadrao)
    },
    getGuiaParcelamento(parcelamentoId, parcelaId) {
      return api(this.tipoParcelamento).getGuiaParcela(parcelamentoId, parcelaId)
        .then(payload => {
          if (payload.data === 'New request') {
            return api(this.tipoParcelamento).getGuiaParcela(parcelamentoId, parcelaId)
          }
          return payload
        })
    },
    baixarEmLote(evt) {
      api(this.tipoParcelamento).baixaEmLote(this.filtroAtual, evt)
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.dasParcelamentoList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.dasParcelamentoList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
  },
}
</script>
